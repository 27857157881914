html {
    width: 100%;
    height: 100%;
}

body {
    min-height: 100%;

    box-sizing: border-box;
    margin: 0;
    padding: 1rem 2rem;

    background-color: #24202c;
    color: white;
}

a {
    color: #dbd3ff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.hidden {
    display: none;
}